<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div style="display: grid; grid-template-columns: 200px 600px; justify-content: space-between">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <div style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 16px; align-items: center;">
          <v-select v-model="year" :clearable="false" :options="yearOptions"></v-select>
          <vs-button color="primary" type="border" icon-pack="feather" icon="icon-file" @click="exportData">Export Data</vs-button>
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" @click="$router.push({ name: 'purchase-to-principle-create' })">Add Item</vs-button>
        </div>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="purchasePrinciples"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ purchasePrinciples.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : purchasePrinciples.length }} of {{ purchasePrinciples.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="getTotalPage"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>

    </div>

    <!-- Prompt Upload File -->
    <vs-prompt
      @accept="putFile"
      :active.sync="popupUpload">
      <div class="con-exemple-prompt">
        <span>Upload {{$i18n.t('Attachment')}}</span>
        <input type="file" name="file" style="width: 100%" ref="file"/>
        <!--        <vs-input type="file" :label="$i18n.t('Attachment')" name="file" ref="invoice" class="mt-3 w-full" />-->
      </div>
    </vs-prompt>

  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

import axios from '@/axios';

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import storage from "@/storage";
import recipe from "@/recipe";


export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {

      loaded: false,
      popupUpload: false,
      searchQuery: '',
      select: {},
      year: new Date().getFullYear(),
      purchasePrinciples: [],

      // AgGrid
      gridApi: null,
      getTotalPage: 0,
      gridOptions: {
        pagination: true,
        onPaginationChanged: this.onPaginationChanged,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('PODate'),
          field: 'date',
          filter: true,
        },
        {
          headerName: this.$i18n.t('PONumber'),
          field: 'po_number',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: this.$i18n.t('PrincipleName'),
          field: 'principle_name',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Attachment'),
          cellRendererFramework: 'CellRendererFile',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            storage: () => storage,
            openPopupUpload: this.showPopupUpload.bind(this),
          }
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          cellClass: 'text-center',
          headerClass: 'text-center',
          cellRendererParams: {
            getData: this.getData.bind(this),
          }
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      }
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    yearOptions () {
      let start = 2013;
      let end = new Date().getFullYear();
      let years = [];
      for (let x = end; x >= start; x--) {
        years.push(x);
      }

      return years;
    }
  },
  methods: {
    exportData () {
      window.open(`${recipe}/api/v1/purchase-principles/export/01-01-${this.year}/31-12-${this.year}/excel`, '_blank');
    },
    onPaginationChanged(){
      return this.getTotalPage = this.gridOptions.api.paginationGetTotalPages()
    },
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },

    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

    handleFileChange (event, files) {
      this.select.file = files[0];
    },
    upload () {

    },
    appendFormData (formData, obj) {
      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });

      return formData;
    },
    putFile () {

      let file = this.$refs.file.files[0];

      let formData = new FormData();
      formData.append('file', file);

      let settings = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      axios.post(`${recipe}/api/v1/purchases/${this.select.id}/upload`, formData, settings).then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title: 'File must PDF/DOCX !',
          color:'danger'
        })

      });

    },
    showPopupUpload (item) {
      this.popupUpload = true;
      this.select = item;
    },

    getData () {
      axios.get(`/purchase-principles?year=${this.year}`).then((resp) => {
        this.purchasePrinciples = resp.data.values;
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      })
      .finally(() => {
        this.onLoaded()
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close()
    }
  },

  // watch
  watch: {
    year () {
      this.getData();
    }
  },

  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  created () {
    this.loading();
    this.getData();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
